import React from 'react'
import Reflux from 'reflux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ContentStore from '../stores/ContentStore'
import { UrlTransform, GetProject, GetFilter } from '../Functions'
import Icon from './Icon'

class Header extends Reflux.Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.stores = [ContentStore]
    }

    componentWillUpdate(nextProps, nextState) {

        let showPrevButton = false,
            showNextButton = false

        let project = GetProject(nextProps.location.pathname, nextState.content[encodeURIComponent(UrlTransform('categories'))])

        if (project && nextProps.style.header && nextState.content[encodeURIComponent('/')]) {

            let filter = GetFilter(nextProps.location.pathname, nextState.content[encodeURIComponent(UrlTransform('categories'))]),
                data = nextState.content[encodeURIComponent('/')]

            let entries = [...data.content[0].content]

            if (filter) {
                entries = entries.filter(entry => entry.categories.findIndex(category => category === filter) > -1)
            }

            let index = entries.findIndex(entry => entry.slug === project);

            if (index > 0) {
                showPrevButton = true
            }

            if (index < entries.length - 1) {
                showNextButton = true
            }

        }

        let mobile = window.innerWidth < 980 ? true : false

        if (this.state.showPrevButton !== showPrevButton || this.state.showNextButton !== showNextButton || this.state.mobile !== mobile) {
            this.setState({
                showPrevButton,
                showNextButton,
                mobile
            })
        }

    }


    showProject = (direction) => {
        let filter = GetFilter(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))]),
            data = this.state.content[encodeURIComponent('/')]

        let entries = [...data.content[0].content]

        entries = entries.filter(entry => entry.id !== 0)

        if (filter) {
            entries = entries.filter(entry => entry.categories.findIndex(category => category === filter) > -1)
        }

        let slug = GetProject(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])

        let index = entries.findIndex(entry => entry.slug === slug);

        switch (direction) {
            case 'previous':

                index--

                if (index < 0) {
                    index = entries.length - 1
                }

                break;

            case 'next':

                index++

                if (index > entries.length - 1) {
                    index = 0
                }

                break;
        }

        let entry = entries[index]

        let url = !filter ? '/projekte/' + entry.slug : '/projekte/' + filter + '/' + entry.slug

        this.context.router.history.push(UrlTransform(url, 'add'))

    }

    render() {
        let style = this.props.style

        if (!this.state.showPrevButton && !this.state.showNextButton) {
            return null
        }

        return (
            <div className="project buttons" style={style.header}>
                {this.state.showPrevButton &&
                    <span className="button previous" onClick={() => this.showProject('previous')}><Icon type="arrow" /><span>voriges Projekt</span></span>
                }
                {this.state.showNextButton &&
                    <span className="button next" onClick={() => this.showProject('next')}><Icon type="arrow" /><span>nächstes Projekt</span></span>
                }
            </div>
        )
    }
}

export default withRouter(Header);