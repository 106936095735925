import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import { UrlTransform, CryptoJSAesDecrypt } from '../../../../Functions'

class Text extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

	componentDidMount(){
        if(this.myRef){
            this.myRef.addEventListener('click', this.anchorLinkListener);
        }
    }

    componentWillUnmount(){
        if(this.myRef){
            this.myRef.removeEventListener('click', this.anchorLinkListener)
        }
    }

    anchorLinkListener = (e) => {
        if(e.target.tagName === 'A') {
          
            let href = e.target.getAttribute('href')

            if(href.indexOf('mailto:')!==-1 || href.indexOf('tel:')!==-1) { 
                

            } else if(href.indexOf('planb')!==-1) {
                e.preventDefault()
                let test = href.split( UrlTransform('planb-tga.de/preview/', 'add') );
                this.context.router.history.replace(test[1])

            } else {
                window.open(href)
                e.preventDefault()
            }           
        }
    }


	render() {
		let data = this.props.data

        if(!this.text) {
            this.text = CryptoJSAesDecrypt(data.text);
        }
 
		return (
			<div className="content standard text" ref={ref => {this.myRef = ref}}>
				<div className="content1">
                    <h2>{data.title}</h2>
                    <div className="cols cols1 size4">
                        <div className="space">
                            <div className="table">
                                <div className="col"><div className="rich" dangerouslySetInnerHTML={{__html: this.text}} /></div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		)
	}
}

export default withRouter(Text)