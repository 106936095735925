import React, { Component } from 'react'
import Icon from '../../../Icon'

import { Transition } from 'react-transition-group'

import { IsTouchDevice } from '../../../../Functions'

const transitionStyles = {
    entering: { opacity: 0  },
    entered: { opacity: 1 },
    exiting: { opacity: 0  }
};

class Slider extends Component {
	constructor(props) {
 		super(props)

        this.state = {
            currentImage: 0,
            direction: 'next',
            round :0,
            showText: true
        }; 

        this.first = true
 	}

 	showSlide = (direction) => {
        let data = this.props.data,
    	currentImage = this.state.currentImage,
        round = this.state.round

        switch(direction) {
        	case 'previous':

        		currentImage--

        		if(currentImage<0) {
        			currentImage = data.length-1
                    round--;
        		}

        		break;

        	case 'next':

        		currentImage++

        		if(currentImage>data.length-1) {
        			currentImage = 0
                    round++
        		}

        		break;
        }


        this.first = false

        this.setState({
            lastImage: this.state.currentImage,	
 			currentImage,
            direction,
            round,
            showText: false
 		});

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            
            this.setState({
                showText: true
            });

        }
        , 200);  
    }

 	handleTouchStart = (e) => {
        this.touchXstart = e.touches[0].clientX;
        console.log(e.touches[0].clientX)
        console.log('handleTouchStart');
    }

    handleTouchMove = (e) => {
        let touchX =  e.touches[0].clientX - this.touchXstart;

        console.log('diff:' + touchX)

        //console.log(e.touches[0].clientX)
        
        if(Math.abs(touchX)>10) {
            this.setState({
                touching: true,
                touchX: touchX 
            });
        }
    }

    handleTouchEnd = (e) => {
        //console.log(e.changedTouches[0].clientX)
        let touchX =  e.changedTouches[0].clientX - this.touchXstart;
        
        console.log(touchX)
        console.log('handleTouchEnd');
        
        if(this.state.touching) {

            if(Math.abs(touchX)>50) {

                this.setState({
                    touching: false
                });

                if(this.state.touchX > 0) {
                    this.showSlide('previous')
                } else {
                    this.showSlide('next')
                }
            } else {

                 this.setState({
                    touching: false,
                    touchX: 0
                });

            }

        }
    }

    componentWillMount = () => {
        if(IsTouchDevice()) {
            this.touch = true;
        }
    }

	render() {
		let data = this.props.data

		let text = [],
        copyright = [],
        content = []

        let translateX = ((this.state.round*data.length) + this.state.currentImage) * -100;

        if(this.state.touching && this.ref) {
            translateX += (this.state.touchX/this.ref.clientWidth)*100; 
        }

        if(data.length) {
            for(let i = -1; i<= data.length; i++) {

                let index = i === -1 ? data.length-1 : i===data.length ? 0 : i
                //console.log(index) 

                let image = data[index]     


                
                //let showText === this.state.showText 
                if(this.state.lastImage===i && !this.state.showText || this.state.currentImage===i && this.state.showText) {
                    text.push(
                        <Transition key={i} in={this.state.currentImage===i && this.state.showText} timeout={{ enter: 0, exit: 200}} mountOnEnter={true} unmountOnExit={true} appear={this.first ? false : true}>
                            {(state) => (
                                <p className="text" style={{...transitionStyles[state]}}>{image.text}</p>
                         )}
                        </Transition>   
                    )
                }
                
               
                copyright.push(
                    <Transition key={i} in={this.state.currentImage===i && this.state.showText} timeout={{ enter: 0, exit: 200}} mountOnEnter={true} unmountOnExit={true}>
                        {(state) => (
                            <p className="copyright" style={{...transitionStyles[state]}}>{image.copyright}</p>
                     )}
                    </Transition>   
                )

                let backgroundImage = '';
                
                /*
                if((i >= this.state.currentImage-1 && i <= this.state.currentImage+1) || i===this.state.lastImage) {
                    backgroundImage = `url(${image.image})`
                }
                */

                backgroundImage = `url(${image.image})`

                let left = ((this.state.round*data.length) + i) * 100;

                content.push(<li style={{backgroundImage: backgroundImage, left: `${left}%`}} key={i}></li>)

            }
        }


        let classNames = []
        if(data.length===1 && !data[0].text) classNames.push('single')
        if(this.state.touching) classNames.push('touching')	
        if(this.touch) classNames.push('touch') 

		return (
			<div className={'slider ' + classNames.join(' ')} ref={ref => {this.ref = ref}}>
				<div className="media clear">
                    <div>
                        {data.length > 1 &&
                            <ul style={{transform: `translateX(${translateX}%)` }} 
                            	className={this.state.touching ? 'touching' : null}
                            	onTouchStart={this.handleTouchStart}
                            	onTouchMove={this.handleTouchMove}
                            	onTouchEnd={this.handleTouchEnd}
                            >
            					{content}
            				</ul>
                        }
                        {data.length <= 1 &&
                            <ul style={{transform: `translateX(${translateX}%)` }} 
                                className={this.state.touching ? 'touching' : null}
                            >
                                {content}
                            </ul>
                        }
                    </div>
    				{data.length>1 &&
    					<span className="button previous" onClick={()=>this.showSlide('previous')}><Icon type="arrow2" /></span>   					
                	}
                	{data.length>1 &&
                		<span className="button next" onClick={()=>this.showSlide('next')}><Icon type="arrow2" /></span>
                	}
                </div>
	            <div className="bottom">
                    {text}
                     {data.length>1 &&
                        <span className="count">{this.state.currentImage+1} / {data.length}</span>
                    }
                </div>           
        		{copyright}
			</div>
		)
	}
}

export default Slider