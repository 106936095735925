import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'

import { UrlTransform, CryptoJSAesDecrypt } from '../../../../Functions'

class Contact extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.contents = []
        this.contentsY = []
    }

	componentDidMount(){
        //window.addEventListener('resize', this.setSizes)
        //window.addEventListener('scroll', this.scroll)

        if(this.myRef){
            this.myRef.addEventListener('click', this.anchorLinkListener);
        }

        //this.setSizes();
    }

    componentWillUnmount(){
        console.log('unmount')

        //window.removeEventListener('resize', this.setSizes)
        //window.removeEventListener('scroll', this.scroll)

        //clearTimeout(this.timeout);

        if(this.myRef){
            this.myRef.removeEventListener('click', this.anchorLinkListener)
        }
    }

    setSizes = () => {
        let scrollY = window.scrollY || window.pageYOffset;

        for(let key in this.contents){
            let content = this.contents[key];

            //if(content) {
            let rect = content.getBoundingClientRect()
            this.contentsY[key] = key==='kontakt' ? 0 : rect.y + scrollY
            //}
        }
        console.log(this.contentsY)
    }

    scroll = () => {

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            let scrollY = window.scrollY || window.pageYOffset,
            current;

            for(let key in this.contentsY){

                let y = this.contentsY[key]

                if(y-300<scrollY) {
                    current = key;
                }

            }

            if(current && !this.pageWillUnmount) {

                let url = current === 'kontakt' ? /kontakt/ : '/kontakt/' + current

                this.skipAutoScroll = true
                this.context.router.history.push( UrlTransform(url, 'add'));
            }
        }
        , 50);  
    }   


    anchorLinkListener = (e) => {

        if(e.target.tagName === 'A') {
          
            let href = e.target.getAttribute('href')

            if(href.indexOf('mailto:')!==-1 || href.indexOf('tel:')!==-1) { 
                

            } else if(href.indexOf('planb')!==-1) {
                e.preventDefault()
                let test = href.split( UrlTransform('planb-tga.de/preview/', 'add') );
                this.context.router.history.replace(test[1])

            } else {
                window.open(href)
                e.preventDefault()
            }           
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        /*
        let anchorLast = this.props.match.params.level2 ? this.props.match.params.level2 : null
        let anchor = nextProps.match.params.level2 ? nextProps.match.params.level2 : null
        let y = anchor ? this.contentsY[anchor] : 0

        if(anchor !== anchorLast && !this.skipAutoScroll) {
            
            window.scrollTo({
                top: y, 
                behavior: "smooth"
            })
        }

        if(!this.first) {
            window.scrollTo({
                top: y
            })
            this.scroll();
        }

        this.first = true
        this.skipAutoScroll = false

        if(nextProps.test.pathname.indexOf('/kontakt')===-1) {
            this.pageWillUnmount = 1;
        }
        */
        
    }


	render() {
		let data = this.props.data
		//console.log(data)

        if(!this.text1) {
            this.text1 = CryptoJSAesDecrypt(data.text_row1);
        }

        if(!this.text2 && data.text_row2) {
            this.text2 = CryptoJSAesDecrypt(data.text_row2);
        }

		return (
			<div className="content standard contact" ref={ref => {this.myRef = ref}}>
				<div className="content1">
                    <h2>{this.props.title}</h2>
                    <div className="image standard">
                        <div style={{backgroundImage: `url(${data.image})`}}>
                        </div>
                    </div>
                    {(data.text_row1 && data.text_row2) &&
                        <div className="cols cols2 size4">
                            <div className="space">
                                <div className="table">
                                    <div className="col"><div className="rich" dangerouslySetInnerHTML={{__html: this.text1}} /></div>
                                    <div className="col"><div className="rich" dangerouslySetInnerHTML={{__html: this.text2}} /></div>
                                </div>
                            </div>
                        </div>
                    }
                    {(data.text_row1 && !data.text_row2) &&
                        <div className="cols cols1 size4">
                            <div className="space">
                                <div className="table">
                                    <div className="col"><div className="rich" dangerouslySetInnerHTML={{__html: this.text1}} /></div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
			</div>
		)
	}
}

export default withRouter(Contact)