import React, { Component } from 'react'
import Slider from './Slider'
import ProjectButtons from '../../../ProjectButtons'

class Project extends Component {
	render() {
		let data = this.props.data,
		style = this.props.style

		let table = []

	
		let test = '<div class="data"><table><tbody>';

		data.data.forEach((entry, i) => {

			table.push(
				<tr key={i}>
					<td dangerouslySetInnerHTML={{__html: entry.label + ':'}}></td> 
					<td dangerouslySetInnerHTML={{__html: entry.text}}></td>
				</tr>
			)

			test += '<tr><td>' + entry.label + ':</td><td>' + entry.text +'</td></tr>';
		})

		test += '</tbody></table></div>';

		test += data.text

		return (
			<div className="content project">
				<h2 dangerouslySetInnerHTML={{__html: data.title ? data.title : this.props.title}}></h2>
				<h3>{data.subline}</h3>
				{
	                style.width > 900 ?
	                (
	                    <div className="table sizeFull">
							<div className="col">
								<Slider data={data.images} />
								<div className="text rich" dangerouslySetInnerHTML={{__html: data.text}}></div>
							</div>
							<div className="col">
								<div className="data">
									<table>
										<tbody>
										{table}
										</tbody>
									</table>
								</div>
							</div>
						</div>
	                )
	                : style.width > 760 ?
	                (
	                	<div>
							<Slider data={data.images} />
							<div className="sizeMedium text rich" dangerouslySetInnerHTML={{__html: test}}>
							</div>
						</div>
	                )
	                :
	                (
	                	<div className="sizeSmall">
							<Slider data={data.images} />
							<div className="data">
								<table>
									<tbody>
									{table}
									</tbody>
								</table>
							</div>
							<div className="text rich" dangerouslySetInnerHTML={{__html: data.text}}></div>
							<ProjectButtons style={style} />
						</div>
	                )
	            }
			</div>
		)
	}
}

export default Project