import React, { Component } from 'react'
import Reflux from 'reflux'
import ContentStore from './stores/ContentStore'
import { withRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ContentStoreActions from './actions/ContentStoreActions'
import './App.css';

import Scrollbar from './components/Scrollbar'
import Header from './components/Header'
import Page from './components/page'
import Footer from './components/Footer'
import axios from 'axios'
import { UrlTransform, UrlTransform2, IsTouchDevice } from './Functions'

class App extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [ContentStore]

        this.state = {
            style: {
                page: {
                    minHeight: 0,
                }
            },
            categories: [],
        }
    }

    componentWillMount = () => {
        if (IsTouchDevice()) {
            this.touch = true;
        }

        //this.touch = true;
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.setSizes)
        this.setSizes()

        if (UrlTransform(this.props.location.pathname) !== '/') {
            ContentStoreActions.getContent(UrlTransform('/', 'add'))
        }

        ContentStoreActions.getContent('footer')
        ContentStoreActions.getContent('categories');

        this.getCategories();
    }

    getCategories = () => {
        axios.get('https://www.planb-tga.de/wordpress/loader.php?url=categories', {}, { headers: {} })
            .then(response => {


                this.setState({
                    categories: response.data,
                })
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    setSizes = () => {

        let rect = this.component.getBoundingClientRect();

        this.setState({
            style: {
                touch: this.touch,
                width: this.component.clientWidth,
                page: {
                    left: rect.left,
                    minHeight: window.innerHeight - document.getElementById('header').clientHeight,
                },
                header: {
                    width: this.component.clientWidth
                }
            }
        })
    }

    render() {
        let currentKey = this.props.location.pathname.split('?')[0] || '/'
        const timeout = { enter: 200, exit: 100 }

        currentKey = UrlTransform2(currentKey, this.state.categories)



        let classNames = []
        if (this.touch) classNames.push('touch')

        return (
            <div id="react" className={classNames.join(' ')}>
                <Scrollbar />
                <Header style={this.state.style} />
                <div className="center main">
                    <div className="inner" ref={component => this.component = component}>
                        <TransitionGroup component="div" id="content">
                            <CSSTransition key={currentKey} timeout={timeout} classNames="fade" appear>
                                <Switch location={this.props.location}>
                                    <Route
                                        path="/:level1?/:level2?/:level3?"
                                        render={(props) => <Page {...props} style={this.state.style} test={this.props.location} />}
                                    />
                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);
