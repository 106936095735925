import React from 'react'
import Reflux from 'reflux'
import {withRouter, NavLink } from 'react-router-dom'
import ContentStore from '../stores/ContentStore'

import { UrlTransform, CryptoJSAesDecrypt } from '../Functions'


class Footer extends Reflux.Component {
	constructor(props) {
        super(props)
        this.stores = [ContentStore]
    }

    componentDidMount(){
        if(this.myRef){
            this.myRef.addEventListener('click', this.anchorLinkListener);
        }
    }

    componentWillUnmount(){
        if(this.myRef){
            this.myRef.removeEventListener('click', this.anchorLinkListener)
        }
    }

    anchorLinkListener = (e) => {
        if(e.target.tagName === 'A') {
          
            let href = e.target.getAttribute('href')

            if(href.indexOf('mailto:')!==-1 || href.indexOf('tel:')!==-1) { 
                

            } else if(href.indexOf('planb')!==-1) {
                e.preventDefault()
                let test = href.split( UrlTransform('planb-tga.de/preview/', 'add') );
                this.context.router.history.replace(test[1])

            } else {
                window.open(href)
                e.preventDefault()
            }           
        }
    }

	render() {
		let data = this.state.content[encodeURIComponent('footer')]

		if(!this.text1 && data) {
            this.text1 = CryptoJSAesDecrypt(data.col1);
        }

		return (
			<div id="footer">
				<span className="top" />
				<span className="bottom" />
				<div className="cols">
					<div className="space">
						<div className="table">
							<div className="col"  dangerouslySetInnerHTML={{__html:  this.text1}} ref={ref => {this.myRef = ref}}>

							</div>
							<div className="col">
								<ul>
									<li><NavLink to={UrlTransform('/karriere', 'add')} activeClassName="selected">Karriere</NavLink></li>
									<li><NavLink to={UrlTransform('/impressum', 'add')} activeClassName="selected">Impressum</NavLink></li>
									<li><NavLink to={UrlTransform('/datenschutz', 'add')} activeClassName="selected">Datenschutz</NavLink></li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Footer);