import React, { Component } from 'react'
import {withRouter, Link} from 'react-router-dom'

import { UrlTransform } from '../../../../Functions'

class Teaser extends Component {
	constructor(props) {
        super(props)

        this.state = {
            hover: false
        }  
    }
	
	onMouseOver = () => {
		if(!this.props.data.title) {
			return
		}

		this.setState({
            hover: true
        })
	}

	onMouseOut = () => {
		this.setState({
            hover: false
        })
	}

	render() {
		let title = this.props.data.title.replace(/<br>/g, ' ## ');
		title = title.split(" ").map((word, idx) => {
			if(word==='##') {
				return <span key={idx} className="clear"></span>
			} else {
            	return <span key={idx}><span dangerouslySetInnerHTML={{__html: word}}></span></span>
			} 
        }); 

        //let url = filter ? this.props.data.url + '?filter=' + filter : this.props.data.url; 

        let url = UrlTransform(this.props.location.pathname) === '/' ? '/projekte/' + this.props.data.slug : UrlTransform(this.props.location.pathname) + '/' + this.props.data.slug 

        if(this.props.data.id===0) {
        	url = UrlTransform('/ueber-uns')
        }

		return (
			<div 
				style={this.props.style}
				onMouseOver={!this.props.touch ? this.onMouseOver : null}
				onMouseOut={!this.props.touch ? this.onMouseOut : null}>
				<Link to={UrlTransform(url, 'add')} className="area"></Link>
				{this.props.data.id!==0 &&
					<div className="hover">
						<div 
							className="text"
							style={{transform: `translateY(${this.state.hover ? -(this.title.clientHeight+6) : 0}px)` }}
							ref={component => this.title = component}	>
								{title}
						</div>
					</div>
				}
			</div>
		)
	}
}

export default withRouter(Teaser);