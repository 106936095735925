import React from 'react'
import Reflux from 'reflux'
import ContentStore from '../../stores/ContentStore'
import ContentStoreActions from '../../actions/ContentStoreActions'

import Overview from './content/overview'
import Project from './content/project'
import About from './content/about'
import Contact from './content/contact'
import Text from './content/text'


import { CSSTransition } from 'react-transition-group'

import { UrlTransform, UrlTransform2 } from '../../Functions'

const components = {
	overview: Overview,
	project: Project,
	about: About,
	contact: Contact,
	text: Text
}

class Page extends Reflux.Component {
	constructor(props) {
		super(props)
		this.stores = [ContentStore]
	}

	updateData(props, state) {
		let path = UrlTransform2(props.location.pathname, state.content[encodeURIComponent(UrlTransform('categories'))])

		if (this.url !== path) {
			this.url = path
			ContentStoreActions.getContent(path)
			window.scrollTo(0, 0)
		}
	}

	componentDidMount() {
		this.updateData(this.props, this.state)
	}

	componentWillUpdate(nextProps, nextState) {
		this.updateData(nextProps, nextState)

		let path = UrlTransform2(this.props.location.pathname, nextState.content[encodeURIComponent(UrlTransform('categories'))])

		let data = nextState.content[encodeURIComponent(UrlTransform(path))]

		if (data) {
			document.title = 'Plan B – ' + data.title.replace(/&#038;/g, "&");
		}
	}

	render() {
		let path = UrlTransform2(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])

		let data = this.state.content[encodeURIComponent(UrlTransform(path))],
			style = this.props.style,
			title = data && data.title ? data.title : '',
			content


		if (data) {
			content = data.content.map((data, idx) => {
				const Content = components[data.type];
				return <Content key={idx} title={title} data={data} style={style} test={this.props.test} match={this.props.match} />;
			});
		}

		//console.log(this.state.content)

		return (
			<div className="page" style={{ minHeight: `${style.page.minHeight}px` }}>
				{!data &&
					<span className="loading">Laden</span>
				}
				<CSSTransition
					in={!!data}
					classNames="fade2"
					appear={true}
					timeout={500}>
					<div>
						{content}
					</div>
				</CSSTransition>
			</div>
		)
	}
}

export default Page;