import React from 'react'
import Reflux from 'reflux'
import PropTypes from 'prop-types'
import { withRouter, NavLink } from 'react-router-dom'
import ContentStore from '../stores/ContentStore'

import ProjectButtons from './ProjectButtons'

import { UrlTransform, GetFilter } from '../Functions'


class Header extends Reflux.Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.stores = [ContentStore]

        this.state = {
            scrolled: false,
            open: false
        };

        let filter = GetFilter(this.props.location.pathname, this.state.content ? this.state.content[encodeURIComponent(UrlTransform('categories'))] : [])

        let about = this.props.location.pathname.indexOf('/ueber-uns') !== -1 ? true : false

        this.state = {
            hoverActive: filter ? 1 : about ? 2 : false,
            hover: filter || about ? true : false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scroll)
    }

    componentWillUpdate(nextProps, nextState) {
        let style = nextProps.style

        let mobile = window.innerWidth < 980 ? true : false

        if (style.touch) {
            mobile = true;
        }

        if (this.state.mobile !== mobile) {
            this.setState({
                mobile
            })
        }

    }

    toggleNavigation = () => {
        let scrollY = window.scrollY || window.pageYOffset;

        this.setState({
            open: !this.state.open,
            lastScroll: scrollY
        })
    }

    hideNavigation = (e) => {
        let href = e.target.getAttribute('href')

        this.setState({
            open: false
        })

        if (href.indexOf('/ueber-uns') === -1 || (href.indexOf('/ueber-uns') !== -1 && this.props.location.pathname.indexOf('/ueber-uns') === -1)) {

            this.setState({
                scrolled: false
            })

            window.scrollTo({
                top: 0
            })

        }
    }

    clickLogo = () => {
        this.setState({
            scrolled: false,
            open: false,
            hoverActive: false,
            hover: false
        })

        window.scrollTo({
            top: 0
        })

    }

    scroll = () => {
        if (this.state.mobile) {
            return
        }

        let scrollY = window.scrollY || window.pageYOffset;

        if (scrollY > 111 && !this.state.scrolled) {
            this.setState({
                scrolled: true
            })
        } else if (scrollY < 111 && this.state.scrolled) {
            this.setState({
                scrolled: false,
                open: false
            })
        }

        /*
        if(this.state.open && this.state.lastScroll < scrollY) {
            this.setState({
                open: false
            })
        }
        */
    }

    onMouseOver = (num) => {

        clearTimeout(this.timeout);
        this.setState({
            hoverActive: num,
            hover: true
        })

    }

    onMouseOut = () => {
        this.timeout = setTimeout(() => {
            let filter = GetFilter(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])

            if (!filter && this.props.location.pathname.indexOf('/ueber-uns') === -1) {
                this.setState({
                    hover: false
                })
            }

            if (filter && this.props.location.pathname.indexOf('/ueber-uns') === -1) {
                this.setState({
                    hoverActive: 1
                })
            }

            if (this.props.location.pathname.indexOf('/ueber-uns') !== -1) {
                this.setState({
                    hoverActive: 2
                })
            }
        }
            , 0);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.location.pathname.indexOf('/karriere') !== -1 || this.props.location.pathname.indexOf('/impressum') !== -1 || this.props.location.pathname.indexOf('/datenschutz') !== -1) {
                this.onMouseOut();
            }
        }
    }


    getSubNavigation = (num) => {

        const isActiveSub = (path) => {
            if (this.props.location.pathname.indexOf('projekte/' + path) !== -1) {
                return true;
            }
        }

        const isActiveSub2 = (path) => {
            if ((this.props.location.pathname.indexOf(path) !== -1) || (path === 'energie-mit-system' && this.props.location.pathname.indexOf('uber-uns') !== -1)) {
                return true;
            }
        }

        let categories = this.state.content[encodeURIComponent('categories')]

        //alert(categories.length)

        switch (num) {
            case 1:
                return (
                    <ul>

                        {categories && categories.map((item) => (<li key={item.slug}><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/projekte/' + item.slug, 'add')}
                            activeClassName="selected" isActive={() => isActiveSub(item.slug)}>{item.name}</NavLink></li>))}


                    </ul>
                )

            case 2:
                return (
                    <ul>
                        <li><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/ueber-uns/energie-mit-system', 'add')}
                            activeClassName="selected" isActive={() => isActiveSub2('energie-mit-system')}>Energie mit System</NavLink></li>
                        <li><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/ueber-uns/leistungsbild', 'add')}
                            activeClassName="selected" isActive={() => isActiveSub2('leistungsbild')}>Leistungsbild</NavLink></li>
                        <li><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/ueber-uns/spektrum', 'add')}
                            activeClassName="selected" isActive={() => isActiveSub2('spektrum')}>Spektrum</NavLink></li>
                        <li><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/ueber-uns/bim', 'add')}
                            activeClassName="selected" isActive={() => isActiveSub2('bim')}>BIM</NavLink></li>
                        <li><NavLink
                            onClick={this.hideNavigation}
                            to={UrlTransform('/ueber-uns/geschichte', 'add')}
                            activeClassName="selected" isActive={() => isActiveSub2('geschichte')}>Geschichte</NavLink></li>
                    </ul>
                )

        }
    }

    render() {
        let style = this.props.style
        let mobile = this.state.mobile

        const isActive = (path, num) => {
            if (this.props.location.pathname.indexOf(path) !== -1 || (num === this.state.hoverActive && this.state.hover)) {
                return true;
            }
        }

        let classes = []
        if (this.state.scrolled) classes.push('scrolled')
        if (this.state.open) classes.push('open')
        if (mobile) classes.push('mobile')




        return (
            <div id="header" className={classes.join(' ')}>
                <div className="center main">
                    <div className="inner">
                        <span id="logo">
                            <NavLink onClick={this.clickLogo} to={UrlTransform('/', 'add')} />
                            <span>Beratende Ingenieure</span>
                        </span>
                        <div id="navigation">
                            {mobile &&
                                <NavLink onClick={this.clickLogo} to={UrlTransform('/', 'add')} ><span>Plan B</span> – Beratende Ingenieure</NavLink>
                            }
                            <ul className="main" onMouseOut={this.onMouseOut}>
                                <li>
                                    <NavLink
                                        to={UrlTransform('/', 'add')}
                                        onClick={this.hideNavigation}
                                        onMouseOver={() => this.onMouseOver(1)}
                                        activeClassName="selected"
                                        isActive={() => isActive('/projekte', 1)}>Projekte</NavLink>
                                    {mobile &&
                                        this.getSubNavigation(1)
                                    }
                                </li>
                                <li>
                                    <NavLink
                                        to={UrlTransform('/ueber-uns/', 'add')}
                                        onClick={this.hideNavigation}
                                        onMouseOver={() => this.onMouseOver(2)}
                                        activeClassName="selected"
                                        isActive={() => isActive('/ueber-uns', 2)}>Über uns</NavLink>
                                    {mobile &&
                                        this.getSubNavigation(2)
                                    }
                                </li>
                                <li>
                                    <NavLink
                                        to={UrlTransform('/kontakt/', 'add')}
                                        onClick={this.hideNavigation}
                                        onMouseOver={() => this.onMouseOver(null)}
                                        activeClassName="selected"
                                        isActive={() => isActive('/kontakt')}>Kontakt</NavLink>
                                </li>
                                {mobile &&
                                    <li className="meta" ><NavLink onClick={this.hideNavigation} to={UrlTransform('/karriere/', 'add')} activeClassName="selected" isActive={() => isActive('/karriere')}>Karriere</NavLink></li>
                                }
                                {mobile &&
                                    <li className="meta" ><NavLink onClick={this.hideNavigation} to={UrlTransform('/impressum/', 'add')} activeClassName="selected" isActive={() => isActive('/impressum')}>Impressum</NavLink></li>
                                }
                                {mobile &&
                                    <li className="meta" ><NavLink onClick={this.hideNavigation} to={UrlTransform('/datenschutz/', 'add')} activeClassName="selected" isActive={() => isActive('/datenschutz')}>Datenschutz</NavLink></li>
                                }
                            </ul>
                            {this.state.hover && !mobile && this.state.hoverActive === 1 &&
                                <div className="sub" onMouseOver={() => this.onMouseOver(this.state.hoverActive)} onMouseOut={this.onMouseOut}>
                                    {this.getSubNavigation(1)}
                                </div>
                            }

                            {this.state.hover && !mobile && this.state.hoverActive === 2 &&
                                <div className="sub" onMouseOver={() => this.onMouseOver(this.state.hoverActive)} onMouseOut={this.onMouseOut}>
                                    {this.getSubNavigation(2)}
                                </div>
                            }
                        </div>
                        <ProjectButtons style={style} />
                        {style.page.left && style.header.width &&
                            <span className="menu" style={{ left: style.page.left + style.header.width }} onClick={() => this.toggleNavigation()}>
                                <span />
                                <span />
                                <span />
                            </span>
                        }
                    </div>
                </div>
                <span className="bottom"><span /></span>
            </div>
        )
    }
}

export default withRouter(Header);