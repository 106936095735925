import React from 'react'
import Reflux from 'reflux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import ContentStore from '../stores/ContentStore'

import { UrlTransform, UrlTransform2, GetFilter } from '../Functions'

class Scrollbar extends Reflux.Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)
        this.stores = [ContentStore]

        this.state = {
            style: {
                width: 0,
                top: 0
            }
        }
    }

    componentDidMount = () => {

        window.addEventListener('resize', this.setSizes)
        window.addEventListener('scroll', this.setSizes)
        this.setSizes()

        //let intervalId = setInterval(this.setSizes, 16.66);
    }

    componentWillUpdate = (nextProps, nextState) => {

        if (this.url !== nextProps.location.pathname) {
            this.preventRefresh = true;
        }

    }

    componentDidUpdate = (prevProps, prevState) => {

        let path = UrlTransform2(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])
        let data = this.state.content[encodeURIComponent(UrlTransform(path))]

        if ((this.url !== this.props.location.pathname) && data) {

            //console.log("------------")
            //console.log(this.url +"!=="+ this.props.location.pathname)
            //console.log(data)

            this.url = this.props.location.pathname
            this.preventRefresh = false

            let delay = 200;

            let filter = GetFilter(this.props.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])
            let filterLast = GetFilter(prevProps.location.pathname, this.state.content[encodeURIComponent(UrlTransform('categories'))])

            if (filter !== filterLast) {
                delay = 400;
            }

            setTimeout(this.setSizes, delay);
        }
    }

    setSizes = () => {
        //console.log('setSizes')
        let totalHeight = document.body.scrollHeight;
        let windowHeight = window.innerHeight;

        //console.log(totalHeight, windowHeight)

        let width = windowHeight / totalHeight * window.innerWidth;
        let scrollY = window.scrollY || window.pageYOffset;

        let top = scrollY / (totalHeight - windowHeight)

        top = (document.body.clientWidth - width) * top

        width += top

        if (width != this.state.style.width || top != this.state.style.top) {
            if (!this.preventRefresh) {
                this.setState({
                    style: {
                        width: width
                    }
                })
            }
        }
    }

    render() {
        return (
            <div id="scrollbar">
                <span ref={bar => this.bar = bar} style={{ transform: `scaleX(${this.state.style.width / 100})` }} />
            </div>
        )
    }
}

export default withRouter(Scrollbar);