import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { UrlTransform } from '../../../../Functions'

class About extends Component {
	static contextTypes = {
		router: PropTypes.object
	}

	constructor(props) {
		super(props)

		this.contents = []
		this.contentsY = []
	}


	componentDidMount() {
		window.addEventListener('resize', this.setSizes)
		window.addEventListener('scroll', this.scroll)

		if (this.myRef) {
			this.myRef.addEventListener('click', this.anchorLinkListener);
		}

		this.setSizes();
	}

	componentWillUnmount() {
		console.log('unmount')

		window.removeEventListener('resize', this.setSizes)
		window.removeEventListener('scroll', this.scroll)

		clearTimeout(this.timeout);

		if (this.myRef) {
			this.myRef.removeEventListener('click', this.anchorLinkListener)
		}
	}

	setSizes = () => {
		let scrollY = window.scrollY || window.pageYOffset;

		for (let key in this.contents) {
			let content = this.contents[key];

			//if(content) {
			let rect = content.getBoundingClientRect()
			this.contentsY[key] = key === 'energie-mit-system' ? 0 : rect.y + scrollY
			//}
		}
	}

	scroll = () => {

		clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			let scrollY = window.scrollY || window.pageYOffset,
				current;

			for (let key in this.contentsY) {

				let y = this.contentsY[key]

				if (y - 300 < scrollY) {
					current = key;
				}
			}

			if (current && !this.pageWillUnmount) {
				this.skipAutoScroll = true
				this.context.router.history.push(UrlTransform('/ueber-uns/' + current, 'add'));
			}
		}
			, 50);
	}


	anchorLinkListener = (e) => {
		if (e.target.tagName === 'A') {

			let href = e.target.getAttribute('href')

			if (href.indexOf('planb') !== -1) {
				e.preventDefault()
				let test = href.split("polycore-agentur.de");
				this.context.router.history.replace(test[1])
			} else {
				window.open(href)
				e.preventDefault()
			}
		}
	}

	componentWillUpdate = (nextProps, nextState) => {
		let anchorLast = this.props.match.params.level2 ? this.props.match.params.level2 : null
		let anchor = nextProps.match.params.level2 ? nextProps.match.params.level2 : null
		let y = anchor ? this.contentsY[anchor] : 0

		if (anchor !== anchorLast && !this.skipAutoScroll) {

			window.scrollTo({
				top: y,
				behavior: "smooth"
			})
		}

		if (!this.first) {
			window.scrollTo({
				top: y
			})
			this.scroll();
		}

		this.first = true
		this.skipAutoScroll = false

		if (nextProps.test.pathname.indexOf('/ueber-uns') === -1) {
			this.pageWillUnmount = 1;
		}

	}

	render() {
		let data = this.props.data

		//console.log(data)

		return (
			<div className="content standard about">
				<div className="content1" ref={ref => { this.contents['energie-mit-system'] = ref }}>
					<h2>{data.content1_title}</h2>
					<div className="image standard">
						<div style={{ backgroundImage: `url(${data.content1_image})` }}>
						</div>
					</div>
					<div className="cols size4 cols2">
						<div className="space">
							<div className="table">
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content1_text_row1 }} /></div>
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content1_text_row2 }} /></div>
							</div>
						</div>
					</div>
				</div>
				<div className="content2" ref={ref => { this.contents['leistungsbild'] = ref }}>
					<h2>{data.content2_title}</h2>
					{data.content2_image_test &&
						<div className="image standard">
							<div style={{ backgroundImage: `url(${data.content2_image})` }}>
							</div>
						</div>
					}
					<div className="cols image">
						<div className="space">
							<div className="table">
								<div className="col">
									<div className="image">
										<div style={{ backgroundImage: `url(${data.content2_image_row1})` }}></div>
									</div>
								</div>
								<div className="col">
									<div className="image">
										<div style={{ backgroundImage: `url(${data.content2_image_row2})` }}></div>
									</div>
								</div>
								<div className="col">
									<div className="image">
										<div style={{ backgroundImage: `url(${data.content2_image_row3})` }}></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="cols">
						<div className="space">
							<div className="table">
								<div className="col">
									<div className="text rich" dangerouslySetInnerHTML={{ __html: data.content2_text_row1 }}></div>
								</div>
								<div className="col">
									<div className="text rich" dangerouslySetInnerHTML={{ __html: data.content2_text_row2 }}></div>
								</div>
								<div className="col">
									<div className="image">
										<div style={{ backgroundImage: `url(${data.content2_image_row3})` }}></div>
									</div>
									<div className="text rich" dangerouslySetInnerHTML={{ __html: data.content2_text_row3 }}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content3" ref={ref => { this.contents['spektrum'] = ref }}>
					<h2>{data.content3_title}</h2>
					{data.content3_image &&
						<div className="image standard">
							<div style={{ backgroundImage: `url(${data.content3_image})` }}>
							</div>
						</div>
					}
					<div className="cols size5 cols2">
						<div className="space">
							<div className="table">
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content3_text_row1 }} /></div>
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content3_text_row2 }} /></div>
							</div>
						</div>
					</div>
					{data.content3_video &&
						<div className="video">
							<iframe width="100%" height="100%" src={data.content3_video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						</div>
					}
				</div>
				<div className="content4" ref={ref => { this.contents['bim'] = ref }}>
					<h2>{data.content4_title}</h2>
					<div className="image standard">
						<div style={{ backgroundImage: `url(${data.content4_image})` }}>
						</div>
					</div>
					<div className="cols size4 cols2">
						<div className="space">
							<div className="table">
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content4_text_row1 }} /></div>
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content4_text_row2 }} /></div>
							</div>
						</div>
					</div>
				</div>
				<div className="content5" ref={ref => { this.contents['geschichte'] = ref }}>
					<h2>{data.content5_title}</h2>
					{data.content5_image &&
						<div className="image standard">
							<div style={{ backgroundImage: `url(${data.content5_image})`, paddingBottom: `${data.content5_image_height / data.content5_image_width * 100}%` }}>
							</div>
						</div>
					}
					<div className="cols size4 cols2">
						<div className="space">
							<div className="table">
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content5_text_row1 }} /></div>
								<div className="col"><div className="rich" dangerouslySetInnerHTML={{ __html: data.content5_text_row2 }} /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(About)