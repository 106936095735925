import Reflux from 'reflux'
import ImageStoreActions from '../actions/ImageStoreActions'

class ImageStore extends Reflux.Store {
    constructor() {
     	super()
     	this.listenables = [ImageStoreActions]
     	this.state = {
            loadedImages: []
		};

		this.url = 'http://localhost/milchhof/planb/deploy/wordpress/loader.php'
        this.url = 'https://www.planb-tga.de/preview/wordpress/loader.php'
    }

    onImageLoaded(id) {
        let loadedImages = this.state.loadedImages
        loadedImages.push(id)

        this.setState({
            loadedImages: loadedImages
        })
    }
}

ImageStore.id = 'ImageStore'

export default ImageStore