var CryptoJS = require("crypto-js");

//let categories = ['hotel', 'buero', 'gewerbe', 'oeffentlich', 'wohnen', 'denkmalschutz', 'logistik']

const UrlTransform = (url, type) => {
	//return type === 'add' ? '/wp' + url : url.replace("/wp/", "/")
	return url
}

const UrlTransform2 = (url, _categories) => {

	//url = url.includes('/ueber-uns') ? '/ueber-uns/' : url
	//url = url.includes('/kontakt') ? '/ueber-uns/' : url

	let categories = _categories ? _categories.map(item => item.slug) : []

	let parts = url.split('/');

	//parts = parts.filter(entry => entry !== '' && entry !== 'wp')
	parts = parts.filter(entry => entry !== '')

	//console.log(parts)

	if (url.includes('/projekte') && parts.length === 3) {
		url = '/projekte/' + parts[2]
	} else if ((url.includes('/projekte') && (parts.length === 2 && categories.indexOf(parts[1]) > -1)) || !parts.length) {
		url = '/'
	}

	if (parts[0] === 'kontakt') {
		url = '/kontakt/'
	}

	if (parts[0] === 'ueber-uns') {
		url = '/ueber-uns/'
	}

	//console.log(url)

	return url
}

const GetProject = (url, _categories) => {

	let categories = _categories ? _categories.map(item => item.slug) : []

	let parts = url.split('/');
	parts = parts.filter(entry => entry !== '' && entry !== 'wp')

	if (url.includes('/projekte')) {
		if (parts.length === 3) {
			return parts[2]
		} else if (parts.length === 2 && categories.indexOf(parts[1]) === -1) {
			return parts[1]
		}
	}

	return false
}

const GetFilter = (url, _categories) => {

	let categories = _categories ? _categories.map(item => item.slug) : []

	let parts = url.split('/');
	parts = parts.filter(entry => entry !== '' && entry !== 'wp')

	if (url.includes('/projekte')) {
		if (parts.length === 3 || (parts.length === 2 && categories.indexOf(parts[1]) > -1)) {
			return parts[1]
		}
	}

	return null
}

function IsTouchDevice() {
	return (('ontouchstart' in window)
		|| (navigator.MaxTouchPoints > 0)
		|| (navigator.msMaxTouchPoints > 0))
}



function CryptoJSAesDecrypt(encrypted_json_string) {
	let passphrase = 'hu8Hgs-8_99';
	var obj_json = JSON.parse(encrypted_json_string);

	var encrypted = obj_json.ciphertext;
	var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
	var iv = CryptoJS.enc.Hex.parse(obj_json.iv);

	var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });


	var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });

	return decrypted.toString(CryptoJS.enc.Utf8);
}

export {
	UrlTransform,
	UrlTransform2,
	GetProject,
	GetFilter,
	IsTouchDevice,
	CryptoJSAesDecrypt
}