import React, { Component } from 'react'

class Icon extends Component {
    getIcon() {

    	switch(this.props.type) {
    		case 'arrow':
    			return (
    				<svg viewBox="0 0 19 36">
    					<defs>
    						<clipPath id="aa" transform="translate(0)"><rect width="19" height="36" fill="none"/></clipPath>
    					</defs>
    					<g clipPath="url(#aa)">
    						<polyline points="18 1 1 18 18 35" fill="none" stroke="#cfcfcf" className="stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    					</g>
    				</svg>
    			)


            case 'arrow2':
                return(
                	<svg viewBox="0 0 24 24">
                		<defs>
                			<clipPath id="a2">
                				<rect width="24" height="24" fill="none"/>
                			</clipPath>
                			<clipPath id="b2">
                				<circle cx="12" cy="12" r="12" fill="none"/></clipPath>
                		</defs>
                		<g clipPath="url(#a2)"><g clipPath="url(#b2)">
                			<polyline points="14 6 8 12 14 18" fill="none" stroke="#fff" className="stroke" strokeLinecap="round" strokeLinejoin="round"/></g>
                			<g clipPath="url(#a2)"><circle cx="12" cy="12" r="11.5" fill="none" stroke="#fff" className="stroke"/>
                			</g>
                		</g>
                	</svg>
                )

        }
    }

    render() {
        return (
            this.getIcon()
        )
    }
 }

 export default Icon;