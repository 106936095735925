import React from 'react'
import Reflux from 'reflux'
import { withRouter } from 'react-router-dom'
import ContentStore from '../../../../stores/ContentStore'
import ImageStore from '../../../../stores/ImageStore'
import ImageStoreActions from '../../../../actions/ImageStoreActions'
import Teaser from './Teaser'

class Overview extends Reflux.Component {
	constructor(props) {
 		super(props)
 		this.stores = [ContentStore, ImageStore]
 	}

    preloadImages = () => {
    	let entry = this.loadingImages[0];

    	//console.log(entry)

    	var i = new Image();

		i.onload = () => {
			
			if(!this.animating) {
				//console.log('loaded')

				this.loadingImages.splice(0, 1);
				

				//loadedImages.push(entry.id)
			    //this.setState({loadedImages: loadedImages})

			    ImageStoreActions.imageLoaded(entry.id)

			    if(this.loadingImages.length) {
			    	this.preloadImages();
			    }
			}
		};

		i.src = entry.image;
    }

    componentDidMount(){
		window.addEventListener('resize', this.resize)
    }

    componentWillUnmount(){
    	window.removeEventListener('resize', this.resize)
    	this.animating = true
    	this.loadingImages = []
    }

    resize = () => {
    	
    	clearTimeout(this.timeout);

    	if(!this.state.resizing) {
	    	this.setState({
	            resizing: true
	        })
        }

    	this.timeout = setTimeout(() => {
            this.setState({
                resizing: false
            })
        }
        , 650);	

    }

	componentWillUpdate(nextProps, nextState) {
		let filterLast = this.props.match.params.level2 ? this.props.match.params.level2 : null,
		filter = nextProps.match.params.level2 ? nextProps.match.params.level2 : null,
		data = nextProps.data

		if(data && (filter !== filterLast || !this.first )) {

			//console.log(this.state.loadedImages.length +'===' + data.content.length)

			/*
			let loadingImages = []

			data.content.map((entry, idx) => {     
				let show = true

				if(filter && entry.categories.findIndex(category => category===filter)===-1 || this.state.loadedImages.indexOf(entry.id) > -1) {
					show = false
				}

				if(show) {
					loadingImages.push(entry)
				} 
			});
			*/

			this.loadingImages = data.content.reduce((acc, entry) => {
				let show = (filter && entry.categories.findIndex(category => category===filter)===-1) ? false : true

			  	if(show && this.state.loadedImages.indexOf(entry.id) === -1) {
					acc.push(entry)
				}

				return acc;
			}, [])



            //this.loadingImages = loadingImages;

            

            if(!this.first) {
            	this.first = true;
            	if(this.loadingImages.length) {
            		this.preloadImages();
            	}

            } else if(this.loadingImages.length) {

            	this.animating = true 

            	setTimeout(() => {

            		this.animating = false 
            		this.preloadImages();

            	}, 650);

            }
		}
	}

	render() {
		let filter = this.props.match.params.level2 ? this.props.match.params.level2 : null

		let data = this.props.data,
		style = this.props.style	

		let content = []
		let space = 16

		//let cols = style.width > 900 ? 5 : style.width >= 640 ? 4 : style.width >= 400 ? 3 : 2,
		let cols = style.width > 988 ? 5 : style.width >= 787 ? 4 : style.width >= 587 ? 3 : 2,

		colWidth = (style.width - (cols-1) * space) / cols,
		colHeights = []

		

		for(let i=0; i<cols; i++) {
			colHeights.push(0)
		}

		let entries = [...data.content]

		for(let i=0; i<data.content.length; i++) {

			let currentCol = colHeights.reduce((iMin, x, i, arr) => x < arr[iMin] ? i : iMin, 0)

			let possibleWidth = 1;

			if(currentCol<cols-1 && colHeights[currentCol] === colHeights[currentCol+1]) {
				possibleWidth = 2
			}

			let entry
			let found
			let currentTry = 0;
			let last

			while(found!==true) {
				entry = entries[currentTry];

				if(entry.width<=possibleWidth || currentTry === entries.length-1) {
					entries.splice(currentTry, 1);
					found = true

					if(currentTry === entries.length) {
						last = true;
					}
				}

				currentTry++;
			}

			let show = true

			if(filter && entry.categories.findIndex(category => category===filter)===-1) {
				show = false
			}

			if(show && last && entry.width > possibleWidth) {
				entry.width = 1
				entry.height = 1
			}

			let left = currentCol * (colWidth + space),
			top = colHeights[currentCol],
			width = entry.width*colWidth + (entry.width-1)*space,
			height = entry.height*colWidth + (entry.height-1)*space,
			transform = `translate(${left}px,${top}px) scale(0)`

			if(show) {
				for(let j=0; j<entry.width; j++) { 
					colHeights[currentCol+j] += height + space
				}

				transform = `translate(${left}px,${top}px) scale(1)`
			}

			//let index = data.content.findIndex(test => test.id===entry.id)

			let image = this.state.loadedImages.indexOf(entry.id) > -1 ? `url(${entry.image})` : 'none' 
			//let image = 'none'

			content[entry.index] = (
				<Teaser 
					key={entry.id}
					style={{
						width: `${width}px`,
						height: `${height}px`,
						backgroundImage: image,
						transform: transform
					}}
					touch={style.touch}
					data={entry}
				/>
			)
		}

		return (
			<div className={'content overview' + (this.state.resizing ? ' resizing' : '')} style={{height: `${Math.max(...colHeights)}px`}}>
				{content}
			</div>
		)
	}
}

export default withRouter(Overview)